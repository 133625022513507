import React, { useEffect,useState } from 'react';
import Album1 from '../../assets/images/PerilousInside.png'
import Album2 from '../../assets/images/BoyDeluxe.png'
import Album3 from '../../assets/images/CaseyHess.png'
import Album4 from '../../assets/images/DavidMorin.png'
import Album5 from '../../assets/images/JamesMason.png'
import Album6 from '../../assets/images/MorganFerguson.png'
import IconSpeaker from '../../assets/images/icon-speaker.svg'
import IconCard from '../../assets/images/icon-card.svg'
import IconShare from '../../assets/images/icon-share.svg'
import IconMusic from '../../assets/images/icon-music.svg'
import IconStore from '../../assets/images/block_five.png'
import 'aos/dist/aos.css';
import AOS from 'aos';
import '../../assets/css/global.css'
import ModalVideo from 'react-modal-video';
import 'react-modal-video/css/modal-video.css';
import Modal from 'react-modal';
import VideoPopup from './VideoPopup';
import Video from '../../assets/images/video.svg'
const Home = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isVideoOpen, setIsVideoOpen] = useState(false);
  const [videoSrc, setVideoSrc] = useState('');

  const openVideo = (src) => {
    setVideoSrc(src);
    setIsVideoOpen(true);
    //window.scrollTo(0, 0); 
  };

  const closeVideo = () => {
    setIsVideoOpen(false);
    setVideoSrc('');
  };
  useEffect(() => {
    AOS.init();
  }, []);
  const videoUrl = 'https://communitymusician.s3.us-west-2.amazonaws.com/cmc/CMC-in-action.mp4'; 

  return (
    <>
      <section className="block-one" data-aos="fade-in" data-aos-duration="2000">
        <div className="relative">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <div>
                  <h1 data-aos="fade-up" data-aos-duration="1000">STOP GIVING YOUR MUSIC AWAY! START SELLING IT!</h1>
                  <h2 data-aos="fade-up" data-aos-duration="1500">COLLECTIBLE MUSIC CARDS PUT THE MUSIC BACK IN YOUR HANDS AND ONTO THE PHONES OF YOUR FANS</h2>
                  <p data-aos="fade-up" data-aos-duration="2000">Join the revolution happening now. You sell the cards to your fans directly. They tap the card on their phone and music is instantly added to their library. The streaming services get nothing! No tricks, no gimmicks and we will even give you 40 free cards to try.</p>
                  <div className="align-right"><img src={IconSpeaker} alt="" width="" height="" /> <img src={IconCard} alt="" width="" height="" /></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="block-two" data-aos="fade-in" data-aos-duration="3000">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 data-aos="fade-up" data-aos-duration="1000">TAP N PLAY</h2>
              <i
                data-aos="fade-up"
                data-aos-duration="2500"
                onClick={() => openVideo(videoUrl)}
                className="videoBtn"
                
              >
               
              </i>
              <h3 data-aos="fade-up" data-aos-duration="2000">Music made easy</h3>
              
            </div>
          </div>
        </div>
      </section>


      <section className="block-three" data-aos="fade-in" data-aos-duration="3000">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="align-center"><img src={IconShare} alt="" width="50" height="55" /></div>
              <h2 data-aos="fade-up" data-aos-duration="1000">Easy to share</h2>
              <h3 data-aos="fade-up" data-aos-duration="2000">FAMILY <span>|</span> FRIENDS <span>|</span> SEND GIFT </h3>
            </div>
          </div>
        </div>
      </section>


      <section className="block-four" data-aos="fade-in" data-aos-duration="3000">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="align-center"><img src={IconMusic} alt="" width="68" height="50" /></div>
              <h2 data-aos="fade-up" data-aos-duration="1000">Instant add to your</h2>
              <h3 data-aos="fade-up" data-aos-duration="2000">Private Library</h3>
            </div>
          </div>
        </div>
      </section>

      <section className="block-five" data-aos="fade-in" data-aos-duration="3000">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 data-aos="fade-up" data-aos-duration="1000">Coming soon <br />to your local</h2>
              <h3 data-aos="fade-up" data-aos-duration="2000">store <img src={IconStore} alt="" width="56" height="50" /></h3>
            </div>
          </div> 
        </div>
      </section>

      <section className="block-six" data-aos="fade-in" data-aos-duration="3000">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 data-aos="fade-up" data-aos-duration="1000">Featured This Week</h2>
              <span data-aos-duration="2000">Collectible Music Cards as a distribution for recorded music</span>
            </div>
            <div className="col-md-4 align-center" data-aos="zoom-out" data-aos-duration="1000">
            <a href="https://www.collectiblemusiccards.com/store/morgan-ferguson/" target='_blank'><img src={Album6} alt="" width="581" height="677" /></a>
            </div>
            <div className="col-md-4 align-center" data-aos="zoom-out" data-aos-duration="2000">
              <div><img src={Album2} alt="" width="581" height="677" /></div>
            </div>
            <div className="col-md-4 align-center" data-aos="zoom-out" data-aos-duration="3000">
              <img src={Album3} alt="" width="581" height="677" />
            </div>
            <div className="col-md-4 align-center" data-aos="zoom-out" data-aos-duration="4000">
              <img src={Album4} alt="" width="581" height="677" />
            </div>
            <div className="col-md-4 align-center" data-aos="zoom-out" data-aos-duration="5000">
              <div><img src={Album5} alt="" width="581" height="677" /></div>
            </div>
            <div className="col-md-4 align-center" data-aos="zoom-out" data-aos-duration="6000">
            
              <img src={Album1} alt="" width="581" height="677" />
            </div>
          </div>
        </div>
      </section>
      <section className="block-seven" data-aos="fade-in" data-aos-duration="3000">
        <div className="container">
          <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-8 relative">
              <div className="feature">
                <div className="item">
                  <h2>App which improves customer experience</h2>
                  <p>creates a digital community for artists, enables engagement with fans for patronage.</p>
                </div>
              </div>
            </div>
            <div className="col-md-2"></div>
          </div>
        </div>
      </section>
      <VideoPopup
        isOpen={isVideoOpen}
        onClose={closeVideo}
        videoSrc={videoSrc}
      />
    </>
  );
}

export default Home;
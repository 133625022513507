import React, { useState } from 'react';
import logo from '../../assets/images/cmc-logo.png';
import '../../assets/css/global.css'
import { Link, useNavigate } from 'react-router-dom';
const Header = ({ openLoginPopup }) => {
  const token = sessionStorage.getItem('token');
  const username = sessionStorage.getItem('username');
  const [showLogout, setShowLogout] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();
  const handleLogout = () => {
    sessionStorage.clear();
    navigate('/');
  };
  const handleLogin = () => {
    navigate('/');
  };
  const handleLoginClick = (e) => {
    e.preventDefault(); 
    openLoginPopup();  
  };

  const toggleDropdown = (e) => {
    e.preventDefault();
    setShowDropdown(!showDropdown);
   
  };
  const handleMyalbums = () => {
    navigate('/albums');
  }

  return (
    <>
      <header className="navigation" data-aos="fade-in" data-aos-duration="3000">
        <div className="header">
          <div className="container">
            <img src={logo} alt="Collectible music cards" width="478" height="73" onClick={handleLogin} />
            {token && username ? (
              <>
              
                <div className="logout"><span style={{color:"#fff", textTransform:"none"}}>{username} <i id="toggleClass" className="" onClick={toggleDropdown}></i>
                {showDropdown ? (
                  <div className="dropdown-content" id="myDropdown">

                    <a href="#" onClick={handleMyalbums}>My Albums</a><br/>
                    <a href="#" onClick={handleLogout}>Logout</a>
                  </div>
                  ):(
                    <div></div>
                  )}
                  </span></div>
              </>
            ) : (
              
              <div className="logout">
                <a href="mailto:order@collectiblemusiccards.com" className='homeorder'>ORDER</a>
                <a href="#" onClick={handleLoginClick}>LOGIN</a></div>
            )}
          </div>
        </div>

      </header>

    </>
  );
}

export default Header;


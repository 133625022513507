import React, { useState, useEffect } from 'react';
import 'aos/dist/aos.css';
import '../../assets/css/global.css';
import UpdateArtworkStatus from '../../apis/updateArtworkStatus/updateArtworkStatus';

const Comment = ({ isOpen, closeCommentPopup, currentComment, setCurrentComment, artworkId, artworkStatus, artworkComment, onSave }) => {
  const [status, setStatus] = useState('');
  const [loading, setLoading] = useState(false);
  const storedUserType = sessionStorage.getItem('userType');
  const [error, setError] = useState('');
  // Set default values when component mounts
  useEffect(() => {
    if (artworkStatus) {
      setStatus(artworkStatus.toString()); // Assuming artworkStatus is a number
    }
  }, [artworkStatus]);

  if (!isOpen) return null;

  const handleCommentSave = async () => {
    setError('');

    if (status === '3' && !currentComment) {
      setError('Comment is required for rejected status.');
      return;
    }
    setLoading(true);
    const authenticationToken = sessionStorage.getItem('token');

    try {
      await UpdateArtworkStatus(authenticationToken, artworkId, status, currentComment);
      closeCommentPopup();
      onSave();
    } catch (error) {
      console.error('Error saving comment:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="popup-fixed"></div>
      <div className="popup-container" data-aos="fade-down" data-aos-easing="linear">
        <div className="login-popup">
          <div className="container help" data-aos="fade-down" data-aos-easing="linear">
            <div className="row">
              <div className="col-md-6 m-auto shadow">
                <div className="row relative">
                  <div className="col-md-12">
                    <span className="close-popup" onClick={closeCommentPopup}></span>
                  </div>
                  <div className="col-md-12">
                    <div className="terms-space help-space">
                      <div>
                        <div className="comment-popup">
                          <table className="table">
                            <tbody>
                            {storedUserType === '2' && (
                              <tr>
                                <td><b>Status:</b></td>
                                <td>
                                  <select
                                    value={status}
                                    onChange={(e) => setStatus(e.target.value)}
                                    className='input comment-select'
                                  >
                                    <option value="1" disabled>Select status</option>
                                    <option value="2">Approved</option>
                                    <option value="3">Rejected</option>
                                  </select>
                                </td>
                              </tr>
                               )}
                               {storedUserType !== '2' && (
                              <tr>
                                <td><b>Status:</b></td>
                                <td>
                                  <select
                                    value={status}
                                    onChange={(e) => setStatus(e.target.value)}
                                    className='input comment-select'
                                    disabled  
                                  >
                                    <option value="" disabled>Select status</option>
                                    <option value="2">Approved</option>
                                    <option value="3">Rejected</option>
                                  </select>
                                </td>
                              </tr>
                               )}
                              {status === '3'  && storedUserType === '2' && (
                                <tr>
                                  <td><b>Comment:</b></td>
                                  <td>
                                    <textarea
                                      value={artworkComment}
                                      onChange={(e) => setCurrentComment(e.target.value)}
                                      placeholder="Add your comment here"
                                      className='comment-text'
                                    />
                                    {error && <div className="error-status-message">{error}</div>}
                                  </td>
                                </tr>
                              )}
                              {artworkComment && storedUserType !== '2' && (
                                <tr>
                                  <td><b> Comment:</b></td>
                                  <td>
                                    <textarea
                                      value={artworkComment}
                                      readOnly
                                      className='comment-text'
                                    />
                                    {error && <div className="error-status-message">{error}</div>}
                                  </td>
                                </tr>
                              )}
                             
                             {storedUserType === '1' ? null : (
                                <tr>
                                  <td></td>
                                  <td>
                                    <button onClick={handleCommentSave} className='comment-btn' disabled={loading}>
                                      {loading ? 'Saving...' : 'Save'}
                                    </button>
                                    <button onClick={closeCommentPopup} className='comment-btn'>Cancel</button>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="terms-footer"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Comment;

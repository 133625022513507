// App.js
import '../src/assets/css/global.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Main from './main/main';
import UploadAlbum from './components/uploadAlbum/uploadAlbum';
import AlbumList from './main/albumList/albumList';
import Tab from './main/audio/tab';
import ArtTab from './main/artWork/artTab';
import ArtWorkForm from './main/artWork/artWork';
import PrivateRoute from './components/privateRoute/privateRoute';
import RequireId from './components/privateRoute/requireId';
import { AlbumProvider } from './components/privateRoute/albumContext'
import CopyWrite from './main/copyWrite/copyWrite';
import ProofList from './main/proofList/proofList';
import { DownloadProvider } from './context/downloadContext';

function App() {
  return (
    <DownloadProvider>
      <AlbumProvider> {/* Wrap your application with AlbumProvider */}
        <Router>
          <Routes>
            <Route path="/" element={<Main />} />
            <Route
              path="/upload"
              element={
                <PrivateRoute>
                  <UploadAlbum />
                </PrivateRoute>
              }
            />
            <Route
              path="/albums"
              element={
                <PrivateRoute>
                  <AlbumList />
                </PrivateRoute>
              }
            />
            <Route
              path="/audio/:id"
              element={
                <PrivateRoute>
                  <RequireId>
                    <Tab />
                  </RequireId>
                </PrivateRoute>
              }
            />
            <Route
              path="/artwork/:id"
              element={
                <PrivateRoute>
                  <RequireId>
                    <ArtTab />
                  </RequireId>
                </PrivateRoute>
              }
            />
            <Route
              path="/proof/:id"
              element={
                <PrivateRoute>
                  <RequireId>
                    <ProofList />
                  </RequireId>
                </PrivateRoute>
              }
            />
            <Route
              path="/form-for-artwork/:id"
              element={
                <PrivateRoute>
                  <RequireId>
                    <ArtWorkForm />
                  </RequireId>
                </PrivateRoute>
              }
            />
            <Route path="/audio" element={<Navigate to="/" replace />} />
            <Route
              path="/artwork"
              element={<Navigate to="/" replace />}
            />
            <Route path="/form-for-artwork" element={<Navigate to="/" replace />} />
            <Route path="/copyright" element={<CopyWrite />} />
          </Routes>
        </Router>
      </AlbumProvider>
    </DownloadProvider>
  );
}

export default App;

import React from 'react';
import cmLogo from '../../assets/images/cm-logo.png';
import '../../assets/css/global.css'
import { useDownload } from '../../context/downloadContext';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  const { isDownloading, downloadProgress, setIsDownloading, setDownloadProgress } = useDownload(); 
  
  return (
    <>
      

      <section className="footer-container" data-aos="fade-in" data-aos-duration="3000">
        <div className="footer">
          <img src={cmLogo} alt="" width="365" height="75" />
          <div className="footer-center">
            <a href="/copyright">Copyright</a> | &copy; {currentYear}. Community Musician,<br />All Rights Reserved.
          </div>
        </div>
      </section>
      {isDownloading && (
        <div className='downloadNotification'>
          Downloading: {downloadProgress}%
        </div>
      )}
      
    </>
  );
}

export default Footer;

import React, { useState } from 'react';
import Header from '../main/header/header';
import Footer from '../main/footer/footer';
import HomePage from '../main/home/home';
import '../assets/css/global.css';
import Login from './login/login';


const Main = () => {
  const [isLoginPopupOpen, setLoginPopupOpen] = useState(false);

  

  const openLoginPopup = () => {
    setLoginPopupOpen(true);
  };

  const closeLoginPopup = () => {
    setLoginPopupOpen(false);
  };

  return (
    <>
      <div>
        <Header openLoginPopup={openLoginPopup} />
        <HomePage />
        <Footer />
      </div>
      {isLoginPopupOpen && <Login closeLoginPopup={closeLoginPopup} />}
      
    </>
  );
}

export default Main;

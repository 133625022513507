import React, { useState, useEffect } from 'react';
import { Table as RsuiteTable, Pagination } from 'rsuite';

import '../../assets/css/global.css';
import '../../assets/css/rsuite.min.css'

const Table = ({ data, columns, pageSize, onSort, loading, pagination = false, height, width, fillHeight, total, minWidth, headerHeight,showRowNumber }) => {
  const [page, setPage] = useState(1);
  const [displayData, setDisplayData] = useState([]);

  useEffect(() => {
   if (Array.isArray(data)) {
      const startIndex = (page - 1) * pageSize;
      const endIndex = Math.min(startIndex + pageSize, data.length);
      const pagedData = data.slice(startIndex, endIndex);
      setDisplayData(pagedData);
    }
  }, [page, data, pageSize]);

  const handleChangePage = (nextPage) => {
    setPage(nextPage);
  };

  return (
    <div className="table" style={{ width: '100%' }}>
      <div style={{ overflow: 'auto' }}>
        <RsuiteTable
          //height={height}
          //fillHeight={fillHeight}
          data={displayData}
          onSortColumn={(sortColumn, sortType) => onSort(sortColumn, sortType)}
          loading={loading}
          hover={false}
          wordWrap='break-word'
          defaultSortType="asc"
          headerHeight={headerHeight}
          autoHeight={true}
        >
           {showRowNumber && (
            <RsuiteTable.Column width={80} align="center">
              <RsuiteTable.HeaderCell> # </RsuiteTable.HeaderCell>
              <RsuiteTable.Cell>
                {(rowData, rowIndex) => (
                  <div>{pageSize * (page - 1) + rowIndex + 1}</div>
                )}
              </RsuiteTable.Cell>
            </RsuiteTable.Column>
          )}
          {columns.map((col) => (
            <RsuiteTable.Column
              key={col.dataKey}
              sortable={col.sortable}
              fullText={true}
              minWidth={col.minwidth}
              width={col.minwidth}
            >
              <RsuiteTable.HeaderCell className="header">{col.title}</RsuiteTable.HeaderCell>
              <RsuiteTable.Cell className="row" dataKey={col.dataKey} headerHeight={headerHeight}>
                {(rowData) => (
                  <div
                    style={{
                      wordBreak: 'break-word',
                      overflowWrap: 'break-word',
                      whiteSpace: 'normal',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      maxHeight: '100%',
                    }}
                  >
                    {col.renderer ? col.renderer(rowData) : rowData[col.dataKey]}
                  </div>
                )}
              </RsuiteTable.Cell>
            </RsuiteTable.Column>
          ))}
        </RsuiteTable>
      </div>
      {pagination && (
        <Pagination
          total={total}
          prev
          next
          activePage={page}
          onChangePage={handleChangePage}
          limit={1}
        />
      )}
    </div>
  );
};

export default Table;
